import type { QuestionTypes } from '@shared/types/options';
import { purify } from './';

export type QuizAnswer = {
  id: number;
  pollId: number;
  questionId: number;
  optionText?: string | null;
  ip: string;
  uuid: string;
  options?: {
    id: number | string;
    answerId: number;
    optionId: number;
  }[];
};

export type QuestionOption = {
  id?: string | number;
  name: string;
  image: string;
  type: number;
};

export function getPreviousAnswerByQuestionType(payload: {
  answers: QuizAnswer[];
  questionType: QuestionTypes;
  questionOptions?: QuestionOption[];
}) {
  const { answers = [], questionType, questionOptions = [] } = payload || {};
  if (!answers.length) return undefined;
  switch (questionType) {
    case 'imageselection':
    case 'selection': {
      const answer = answers?.[0]?.options?.map((selection) => selection.optionId) || [];
      const previousAnswer = questionOptions.filter((option) => answer.includes(option?.id as number));
      return previousAnswer || [];
    }
    case 'singlerowtext':
    case 'numericinput':
    case 'heightinput':
    case 'weightinput':
    case 'dateentry':
    case 'timeentry':
      return purify(answers?.[0]?.optionText || '');
    case 'locationpicker':
      return JSON.parse(purify(answers?.[0]?.optionText || ''));
    case 'file': {
      const files = JSON.parse(purify(answers?.[0]?.optionText || '')) || [];
      const previousFiles = files.map((fileUrl: string) => {
        return { source: purify(fileUrl), options: { type: 'remote' } };
      });
      return previousFiles;
    }
    default:
      return undefined;
  }
}
